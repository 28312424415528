html, body
{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

*
{
  font-family: helvetica;
  font-size: 16px;
  font-weight: normal;
  /*color:#4a4a4a;*/
}

.openfront-back
{
  position: absolute;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  margin: 0px;
  padding: 0px;
  display: grid;
	grid-template-rows: 1fr auto 1fr;
  margin: 25px;
}

.openfront-center
{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.openfront-buttons
{
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
}

.p-line-end
{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 150px 15px;
  margin-bottom: 10px
}

.openfront-window
{
  width: 300px;
  height: auto;
  min-height: 300px;
}

.openfront-logo
{
  position: relative;
  float: left;
  width: 300px;
  height: 150px;
}

.openfront-title
{
  position: relative;
  float: left;
  height: 40px;
  font-size: 28px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  width: 100%;
  color:#4a4a4a;
}

.openfront-text
{
  position: relative;
  float: left;
  height: auto;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  width: 100%;
  text-align: center;
  color:#4a4a4a;
  margin-bottom: 20px;
  margin-top: 10px;
}

.openfront-logo-image
{
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
}

.openfront-placeholder
{
  width: 100%;
  height: 25px;
  position: relative;
  float: left;
}

.openfront-error
{
  position: relative;
  float: left;
  height: auto;
  margin-top: 15px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 17px;
  width: 100%;
  color:#ae0000;
}

.openfront-result-container
{
  height: calc(100vh - 250px);
  width: 100%;
}

.openfront-result-box
{
  position: relative;
  float: left;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  height: auto;
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
}

.center-loader
{
  position: relative;
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

@media screen and (max-width: 800px)
{
  .openfront-result-box
  {
    grid-template-rows: auto auto;
    grid-template-columns: none;
  }
}

.openfront-input
{
  width:100%;
  position: relative;
  float: left;
  height: auto;
  display: grid;
  grid-template-columns:1fr  150px 1fr;
}

.openfront-result-key
{
  height: auto;
  line-height: 20px;
  text-align: left;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bolder;
  word-break:break-all;
  background-color: #eee;
  color: #2b2b4f;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.openfront-result-value
{
  height: auto;
  line-height: 20px;
  text-align: left;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color:#4a4a4a;
}

.openfront-result-value-spezial
{
  position: relative;
  float: left;
  border: 1px dashed #848484;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
}
